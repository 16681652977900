import { CLASSES } from '@/scripts/helpers/constants';
import $ from 'jquery';

export default mode => {
  const categoriesMenu = '.js-categories-menu';

  $(categoriesMenu).on('click', function () {
    $(this).toggleClass(CLASSES.IS_OPEN);
  });

  function handleCloseMenu(e) {
    if ($(e.target).closest(categoriesMenu).length === 0) {
      $(categoriesMenu).removeClass(CLASSES.IS_OPEN);
    }
  }

  $(document).click(handleCloseMenu);
};
